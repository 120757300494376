<template>
  <!-- 绿海 -->
  <div id="CustomerDZMotor">
    <!-- CustomerDZMotor头部内容 -->
    <div class="CustomerDZMotorHeader">
      <!-- 头部组件 -->
      <Header />
    </div>
    <!-- CustomerDZMotor中间内容 -->
    <div class="CustomerDZMotorCenter">
      <!-- 客户介绍 -->
      <div class="contentOne">
        <div class="titleOne">客户介绍</div>
        <div class="conInfo">
          <img src="~assets/img/CustomerLH/公司@2x.png" />
          <div class="infoOne">
            &#160;&#160;&#160;&#160;&#160;浙江绿海制盐有限责任公司是省盐业集团公司的直属控股子公司，是国家食盐定点生产企业，也是全省仅有的传统浙盐生产加工企业和纯天然绿色海盐、营养日晒盐、洗化盐、保健盐生产企业。公司拥有国内先进的现代化食盐小包装全自动生产线
            2 条，年产能 10 万吨，拥有洗化盐生产线、海鲜精生产线各一条，并建有仓储、配送中心和研发中心，产品已通过 ISO9001 质量管理体系及 ISO14001
            环境管理体系认证。公司被省国资委列为重点培育上市对象。<br /><br />
            &#160;&#160;&#160;&#160;&#160;公司主要从事日晒自然盐、多品种盐、洗化盐、保健品和调味
            品的开发、生产和销售。产品主要有普陀佛盐、观音素盐、绿色日晒自然盐系列，营养日晒盐系列，低钠自然盐系列，罐装盐系列，海鲜精系列，螺旋藻系列，磨砂盐系列，沐浴盐乳系列，足浴盐系列，泡澡盐系列，洁面盐乳系列、精油皂系列、香氛沐浴盐乳系列、洗发盐乳系列，润肤乳系列，盐牙膏系列，保健热敷盐系列、足浴泡腾片系列等产品。
          </div>
        </div>
      </div>
      <!-- 项目内容 -->
      <div class="contentTwo">
        <div class="titleTwo">项目内容</div>
        <div class="titleT">
          对入库称重秤、2 台小包装机进行数据采集改造及联网，同时实 施智能仓储管理系统，对原材料入库、投料进行数字化管控。2020 年 12 月试点项目实施完成，实现了联网生产设备的实时监控，提升了仓储管控效率。
        </div>
      </div>
      <!-- 项目成效 -->
      <div class="contentTwo">
        <div class="titleTwo">项目成效</div>
        <div class="titleT">
          通过实施项目全面建设数字化工厂打下了坚实的技术基础。并结合公司现有的ERP，被评为2021年岱山县数字化标杆企业。<br />
          <el-icon class="el-icon-trophy" style="color: #ffc24c;font-size: 18px;"></el-icon> 2021年岱山县数字化标杆企业。
        </div>
      </div>

      <!-- 项目概况 -->
      <div class="contentThree">
        <ul class="imgContent">
          <li v-for="(item, index) in imgContents" :key="index">
            <img :src="item.Imgs" style="width: 350;height: 324px;" />
            <!-- <div class="imgBottom">{{ item.textPro }}</div> -->
          </li>
        </ul>
      </div>
    </div>
    <!-- 底部内容 -->
    <Footer />
    <!-- 回到顶部 -->
    <el-backtop><img src="~assets/img/CustomerDZMotor/icon-一键置顶@2x.png" alt="icon-回到顶部@2x.png" /></el-backtop>
  </div>
</template>
<script>
import Header from "components/Header/dsHeader";
import Footer from "components/Footer/dsFooter";
export default {
  name: "CustomerDZMotor",
  data() {
    return {
      imgContents: [
        {
          Imgs: require("assets/img/board/lh/01.png"),
          textPro: ""
        },
        {
          Imgs: require("assets/img/board/lh/01.png"),
          textPro: ""
        }
      ],

    };
  },
  mounted() {
    document.title = '岱山汽船配工业互联网平台';
  },
  components: {
    Header,
    Footer
  }
};
</script>
<style lang="less" scope>
#CustomerDZMotor {
  width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;

  // CusCustomerDZMotor头部样式
  .CustomerDZMotorHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/CustomerLH/banner@2x.jpg") no-repeat;
    background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
  }

  // CustomerDZMotor中间内容样式
  .CustomerDZMotorCenter {
    width: 1220px;
    margin: 80px auto 101px;

    // 客户介绍
    .contentOne {
      .titleOne {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }

      .conInfo {
        margin-top: 30px;
        display: flex;

        img {
          width: 560px;
          height: 343px;
        }

        .infoOne {
          width: 590px;
          height: 157px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
          margin: 3px 0 0 40px;
        }
      }
    }

    // 项目价值
    .contentTwo {
      margin-top: 60px;

      .titleTwo {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }

      .titleT {
        width: 1200px;
        // height: 72px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 28px;
        color: rgba(51, 51, 51, 0.85);
        margin-top: 30px;
      }
    }

    // 项目概况
    .contentThree {

      // margin-top: 60px;
      .titleThree {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }

      .imgContent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        // margin-top: 30px;
        padding: 0;

        li {
          margin-bottom: 30px;
          background-color: #fff;



          .imgBottom {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.85);
            margin-top: 15px;
            text-align: center;
          }
        }
      }
    }
  }
}</style>
<style lang="less">.el-backtop {
  img {
    width: 30px;
    height: 30px;
  }
}</style>
